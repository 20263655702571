<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init" class="cur-a">安知学习过的视频列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
                @sort-change="changeSort"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="媒体名称"
                  align="left"
                  prop="kpointName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="最后学习时间"
                  align="left"
                  prop="lastStudyTime"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="学习人次"
                  align="left"
                  prop="studyNum"
                  show-overflow-tooltip
                  sortable="custom"
                  min-width="100"
              />
              <el-table-column
                  label="媒体时长（秒）"
                  align="left"
                  prop="kpointDuration"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="媒体大小（比特）"
                  align="left"
                  prop="fileSize"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="视频id"
                  align="left"
                  prop="kpointVideoId"
                  show-overflow-tooltip
                  min-width="160"
              />
              <el-table-column
                  label="分类"
                  align="left"
                  prop="categoryName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="创建时间"
                  align="left"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="操作"
                  align="center"
                  width="140px"
                  fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="showVideo(scope.row)"
                  >预览</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      kpointVideoId: null,
      agencyName: "",
      agencyMobile: "",
      areaId: "",
      trainTypeId: "",
      agencyType: "",
      companyName: "",
      studyNumOrder: "",
      agencyCooperationType: "", //合作方式
      agentType: [
        {
          label: "个人代理",
          value: "10",
        },
        {
          label: "企业代理",
          value: "20",
        },
      ],
      agentStartTime: "",
      AgentEndTime: "",
      areatreeList: [],
      trainTypeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      /* 合作方式 */
      CooperationModeList: [
        {
          label: "按学员",
          value: "10",
        },
        {
          label: "按机构",
          value: "20",
        },
      ],
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    this.getareatree();
    this.getTrainTypeList();
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        this.areatreeList = data;
      });
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
          .then((res) => {
            let data = res.data || [];
            this.trainTypeList = data;
          })
          .catch((err) => {
            return;
          });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.$route.query.kpointVideoId) {
        params.kpointVideoId = this.$route.query.kpointVideoId;
      }
      if (this.studyNumOrder) {
        params.studyNumOrder = this.studyNumOrder;
      }
      this.doFetch({
        url: "/biz/checkvideo/ali-vod-info/studyPageList",
        params,
        pageNum,
      });
    },
    // 修改
    handleCreate(stu, id = "") {
      this.$router.push({
        path: "/web/agentAdd",
        query: { id, stu },
      });
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
          .then((res) => {
            this.cityTree = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    handleCascader(val) {
      console.log(val);
    },
    handleTrainType(val) {
      console.log(val);
    },
    /* 绑定机构 */
    showVideo(data) {
      this.$post("/biz/checkvideo/ali-vod-info/kpointList", {kpointVideoId: data.kpointVideoId})
          .then((res) => {
            this.$store.dispatch("handleVideoUrl", {
              kpointVideoId: data.kpointVideoId,
              kpointName: data.kpointName,
              kpointVideoUrl: data.kpointVideoId,
              kpointSource: '10',
              noBodyLearning: true,
              details: res.data
            });
          });
    },
    changeSort({column, prop, order}) {
      this.studyNumOrder = order;
      this.getData();
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.agencyName = ""),
              (this.agencyMobile = ""),
              (this.areaId = ""),
              (this.trainTypeId = ""),
              (this.agentStartTime = ""),
              (this.AgentEndTime = ""),
              (this.companyName = ""),
              (this.pageNum = 1),
              this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped></style>
